import React, { useState } from "react";
import BusinessUnitIndex from "../../pages/Settings/businesUnit/BusinesUnitIndex";
import { useGetSettingBusinessUnitQuery } from "../../features/SCMApi";
import usePagination from "../Pagination/Pagination";
import NoRecord from "../common/NoRecord";
import { IconButton, Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Card from "react-bootstrap/Card";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CreateBusinessUnitSettingModal from "./SettingModal/Create BusinessUnitSettingModal";
import DeleteBusinessUnitModal from "./DeleteBusinessUnitModal";

const BusinessUnit = () => {
  const { data: BusinessUnitInfo } = useGetSettingBusinessUnitQuery();

  const [clientId, setClientId] = useState(null);
  const [createClientModal, setCreateclient] = useState(false);
  let [page, setPage] = useState(1);

  //Delete Client  toggle
  const [showClientDeleteModal, toggelClientDeleteModal] =
    React.useState(false);
  const toggleClientDeleteHandler = (e) => {
    toggelClientDeleteModal(!showClientDeleteModal);
  };

  const PER_PAGE = 5;
  const count = Math.ceil(BusinessUnitInfo?.data?.length / PER_PAGE);
  const _DATA = usePagination(BusinessUnitInfo?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  function createClientHandler() {
    setCreateclient(true);
  }
  return (
    <BusinessUnitIndex
      handleSearchChange={handleSearchChange}
      placeholder={"search BusinessUnit..."}
    >
      {BusinessUnitInfo?.data?.length !== 0 ? (
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: "22px", fontWeight: "500" }}>
                Business Unit
              </p>
              <IconButton
                sx={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#064b4f",
                  color: "#ececec",
                  "&:hover": {
                    backgroundColor: "#186569",
                    color: "#ececec",
                  },
                }}
                onClick={createClientHandler}
              >
                <AddIcon style={{ fontSize: 30 }} />
              </IconButton>
            </div>
            <div className="divider"></div>
            <div className="setting-accordion-header row align-items-center bg-white card-header accordionHeader">
              <div className="row align-items-center">
                <div className="col-2 text-center">
                  <p className="headerStyle">No.</p>
                </div>
                <div className="col-6 text-center">
                  <p className="headerStyle">Name</p>
                </div>
                <div className="col-2 text-center">
                  <p className="headerStyle">Action</p>
                </div>
              </div>
            </div>
            {_DATA.currentData()?.map((index, info) => {
              return (
                <Card className="mb-2 mt-0">
                  <div className="row align-items-center bg-white card-header accordionHeader">
                    <div className="col-2 text-center">
                      <p className="prg">{info + 1}</p>
                    </div>
                    <div className="col-6 text-center">
                      <p className="prg">{index?.name}</p>
                    </div>
                    <div className="col-2 text-center">
                      <IconButton
                        variant="text"
                        size="small"
                        sx={{
                          backgroundColor: "#fff",
                          color: "#FF5252",
                          "&:hover": {
                            backgroundColor: "#FF5252",
                            color: "#fff",
                          },
                          marginBottom: "3px",
                        }}
                        onClick={(e) => {
                          setClientId(index?.id);
                          toggleClientDeleteHandler();
                        }}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
          {createClientModal ? (
            <CreateBusinessUnitSettingModal
              modal={setCreateclient}
              refreshe={useGetSettingBusinessUnitQuery}
            />
          ) : (
            <></>
          )}
          <DeleteBusinessUnitModal
            show={showClientDeleteModal}
            handleDeleteModal={toggleClientDeleteHandler}
            clientId={clientId}
            refreshe={useGetSettingBusinessUnitQuery}
          />
          <div className="mt-3 mb-3">
            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            ) : (
              ""
            )}
          </div>{" "}
        </div>
      ) : (
        <NoRecord />
      )}
    </BusinessUnitIndex>
  );
};

export default BusinessUnit;
