import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function LocalBoq(props) {
  const { boq = [] } = props.data; // Safely access boqs from props.data
  console.log(props.data, "!@#$%^&*()(*&^%$#@!");
  const HandleCancel = () => {
    props.modal(false);
  };

  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Local Boq</div>
            <CloseIcon onClick={HandleCancel} />
          </div>

          {/* Display the list of BOQ items */}
          <div className="modal-body">
            {boq?.length ? (
              <>
                <table
                  style={{
                    width: "auto",
                    minWidth: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead className="stock-table-header">
                    <tr>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "50px" }}
                      >
                        No
                      </th>

                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "200px" }}
                      >
                        Item part number
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "200px" }}
                      >
                        Item description
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "100px" }}
                      >
                        Qty
                      </th>
                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "100px" }}
                      >
                        Total Price
                      </th>

                      <th
                        className="stock-table-header-text text-start"
                        style={{ width: "100px" }}
                      >
                        Remaining Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {boq.map((boq, index) => (
                      <tr
                        className={`stock-table-row ${
                          boq?.discounted_total_cost <= 0
                            ? "bg-danger text-white"
                            : ""
                        }`}
                      >
                        <td className="text-start">{(index += 1)}</td>
                        <td className="text-start">{boq?.item_part_number}</td>
                        <td className="text-start">{boq?.item_description}</td>
                        <td className="text-start">{boq?.quantity}</td>

                        <td className="text-start">
                          {" "}
                          {Number(boq?.total_price)?.toLocaleString()}
                        </td>
                        <td className="text-start">
                          {" "}
                          {Number(boq?.discounted_total_cost)?.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div></div>
              </>
            ) : (
              <p>No BOQ items available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
