import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const GrossReportSolution = ({ data }) => {
  const downloadExcel = async () => {
    if (!data || data.length === 0) {
      alert("No data to download.");
      return;
    }

    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();

    worksheet.mergeCells("A1:E1");

    const titleCell = worksheet.getCell("A1");

    titleCell.value = `IE ${formattedDate} Profitability Projection by Solution`;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell.font = { name: "Times New Roman", bold: true, size: 14 };
    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };

    // Add table headers
    const headerRow = worksheet.addRow([
      "No",
      "Solution",
      "Revenue",
      "Actual Cost",
      "Gross Profit Amount",
      "Collected Amount",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = { bold: true, size: 12, name: "Times New Roman" };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.columns = [
      { width: 10 }, // No
      { width: 30 }, // Solution
      { width: 20 }, // Revenue
      { width: 20 }, // Actual Cost
      { width: 25 }, // Gross Profit Amount
      { width: 25 }, // Collected Amount
    ];

    // Initialize totals
    let count = 0;
    let totalRevenue = 0;
    let totalCost = 0;
    let totalGrossProfit = 0;
    let totalCollected = 0;

    // Populate data rows
    data.forEach((item) => {
      const revenue = item?.revenue || 0;
      const cost = item?.cost || 0;
      const grossProfit = revenue - cost;
      const collectedAmount = item?.collected_amount || 0;

      totalRevenue += revenue;
      totalCost += cost;
      totalGrossProfit += grossProfit;
      totalCollected += collectedAmount;

      const dataRow = worksheet.addRow([
        ++count, // No
        item.solution_name || "-", // Solution
        revenue.toLocaleString(), // Revenue
        cost.toLocaleString(), // Actual Cost
        grossProfit.toLocaleString(), // Gross Profit Amount
        collectedAmount.toLocaleString(), // Collected Amount
      ]);

      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      });
    });

    // Add footer row with totals
    const footerRow = worksheet.addRow([
      "Total",
      "", // Solution column left blank
      totalRevenue.toLocaleString(),
      totalCost.toLocaleString(),
      totalGrossProfit.toLocaleString(),
      totalCollected.toLocaleString(),
    ]);
    footerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = { bold: true, size: 12, name: "Times New Roman" };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = { vertical: "middle", horizontal: "right" };
    });

    // Generate and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Gross_Report.xlsx");
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default GrossReportSolution;
