import React, { useState, useEffect, useMemo } from "react";
import { useGetGrossProfitProjectAllBirrQuery } from "../../../features/SCMApi";
import GrossReport from "./GrossReport";
import usePagination from "../../Pagination/Pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Tooltip,
  Paper,
  Pagination,
} from "@mui/material";

const ProjectAll = () => {
  const { data: gross } = useGetGrossProfitProjectAllBirrQuery();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const filteredData = useMemo(() => {
    return (
      gross?.data?.filter((items) => {
        if (search === "") return true;
        return items.sector_name?.toLowerCase().includes(search.toLowerCase());
      }) || []
    );
  }, [gross?.data, search]);

  const count = Math.ceil(filteredData.length / PER_PAGE);
  const _DATA = usePagination(filteredData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  let grossData = gross?.data?.filter(
    (items) => items?.gross_profit?.length !== 0
  );

  let TotalRevenueAmount = 0;
  grossData?.map(
    (items, index) =>
      (TotalRevenueAmount += Number(items?.gross_profit[0].revenue))
  );

  const totals = useMemo(() => {
    return filteredData.reduce(
      (acc, item) => {
        if (item.gross_profit && item.gross_profit.length > 0) {
          const grossProfit = item.gross_profit[0];
          acc.revenue += Number(grossProfit.revenue) || 0;
          acc.cost += Number(grossProfit.cost) || 0;
          acc.gross_profit += Number(grossProfit.gross_profit) || 0;
        }
        return acc;
      },
      { revenue: 0, cost: 0, gross_profit: 0 }
    );
  }, [filteredData]);

  return (
    <div>
      {filteredData.length > 0 && <GrossReport data={filteredData} />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="gross profit table">
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Revenue</TableCell>
              <TableCell>Actual Cost</TableCell>
              <TableCell>Gross Profit</TableCell>
              <TableCell>GPP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA?.currentData()?.map((items) => (
              <TableRow
                key={items?.project_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  <Tooltip title={items?.project}>
                    <span>
                      {items?.project?.length > 20
                        ? items?.project.slice(0, 20) + "..."
                        : items?.project}
                    </span>
                  </Tooltip>
                </TableCell>
                {items?.gross_profit?.length !== 0 ? (
                  items?.gross_profit?.map((gross) => (
                    <React.Fragment key={gross?.currency}>
                      <TableCell>
                        {(gross?.revenue || "-").toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {(gross?.cost || "-").toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {(gross?.gross_profit || "-").toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {TotalRevenueAmount
                          ? Number(
                              (gross?.revenue / TotalRevenueAmount) * 100
                            )?.toLocaleString()
                          : 0}
                        %
                      </TableCell>
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={1} align="right">
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.revenue.toLocaleString()}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.cost.toLocaleString()}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.gross_profit.toLocaleString()}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  {totals.revenue
                    ? (
                        (totals.gross_profit / totals.revenue) *
                        100
                      ).toLocaleString()
                    : 0}
                  %
                </strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {count > 1 && (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "20px 0",
          }}
        />
      )}
    </div>
  );
};

export default ProjectAll;
