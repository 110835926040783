import React from "react";
import { useDeleteFiscalYearMutation } from "../../features/SCMApi";

const DeleteFiscalYearModal = ({ id, onClose }) => {
  const [deleteFiscalYear, res] = useDeleteFiscalYearMutation();
  let payload = {
    id: id,
  };
  const onDelete = () => {
    deleteFiscalYear(payload)
      .unwrap()
      .then((response) => {
        onClose();
        console.log("response for success: ", response);
      })
      .then((error) => {
        console.log("error: ", error);
      });
  };
  return (
    <div className="modal-container" show={true} onHide={onClose}>
      <div className="modal-dialog  modal-dialog-centered" role="document">
        <div className="modal-content" style={{ minHeight: "0" }}>
          <div className="modal-header">
            <h5 className="modal-title">Edit Fiscal Year</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>

          <div className="modal-body">
            Are You Sure to Delete This Fiscal Year
          </div>
          <div className="modal-footer">
            <button
              onClick={onClose}
              type="button"
              className="form-cancel"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() => onDelete()}
              type="button"
              className="form-save"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFiscalYearModal;
