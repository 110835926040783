import React, { useState, useEffect, useMemo } from "react";
import { useGetGrossProfitBusinessUnitAllBirrQuery } from "../../../features/SCMApi";
import GrossReportBusinessUnit from "./GrossReportBusinessUnit";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  Pagination,
} from "@mui/material";

const BusinessUnitAll = () => {
  const { data: gross } = useGetGrossProfitBusinessUnitAllBirrQuery();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const filteredData = useMemo(() => {
    return (
      gross?.data?.filter((items) => {
        if (search === "") return true;
        return items.sector_name?.toLowerCase().includes(search.toLowerCase());
      }) || []
    );
  }, [gross?.data, search]);

  const count = Math.ceil(filteredData.length / PER_PAGE);
  const _DATA = filteredData.slice((page - 1) * PER_PAGE, page * PER_PAGE);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const totals = useMemo(() => {
    return filteredData.reduce(
      (acc, item) => {
        acc.revenue += Number(item?.revenue || 0);
        acc.cost += Number(item?.cost || 0);
        acc.amount += Number(item?.actual_amount || 0);
        acc.grossProfit += Number(item?.revenue - item?.cost || 0);
        return acc;
      },
      { revenue: 0, cost: 0, grossProfit: 0, amount: 0 }
    );
  }, [filteredData]);

  return (
    <div>
      {filteredData.length > 0 && (
        <GrossReportBusinessUnit data={filteredData} />
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="gross profit table">
          <TableHead>
            <TableRow>
              <TableCell>Sector Name</TableCell>
              <TableCell align="right">Revenue</TableCell>
              <TableCell align="right">Actual Cost</TableCell>
              {/* <TableCell align="right">Collected Amount</TableCell> */}
              <TableCell align="right">Gross Profit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA.map((items) => (
              <TableRow
                key={items.business_unit_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {items.business_unit_name || "N/A"}
                </TableCell>
                <TableCell align="right">
                  {items?.revenue
                    ? Number(items?.revenue).toLocaleString()
                    : "-"}
                </TableCell>
                <TableCell align="right">
                  {items?.cost ? Number(items?.cost).toLocaleString() : "-"}
                </TableCell>
                {/* <TableCell align="right">
                  {items?.actual_amount
                    ? Number(items?.actual_amount).toLocaleString()
                    : "-"}
                </TableCell> */}
                <TableCell align="right">
                  {items?.revenue - items?.cost
                    ? Number(items?.revenue - items?.cost).toLocaleString()
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={1} align="right">
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.revenue.toLocaleString()}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.cost.toLocaleString()}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.amount.toLocaleString()}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.grossProfit.toLocaleString()}</strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {count > 1 && (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "20px 0",
          }}
        />
      )}
    </div>
  );
};

export default BusinessUnitAll;
