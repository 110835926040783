import React from "react";
import { NavLink } from "react-router-dom";
import PaymentApproval from "../../assets/SVG/paymentApproval.png";
import receivable from "../../assets/SVG/receivable.png";
import payable from "../../assets/SVG/payable.png";
import ForexApproval from "../../assets/SVG/forex.png";
import PaymentCollection from "../../assets/SVG/PaymentCollection.png";
import SupplierPayment from "../../assets/SVG/SupplierPayment.png";
import VarianceAnalysis from "../../assets/SVG/VarianceAnalaysis.png";
import Prf from "../../assets/SVG/Prf.png";
import Guarantee from "../../assets/SVG/guarantee.png";
import Cost from "../../assets/SVG/reduce-cost.png";
import Gross from "../../assets/SVG/gross-profit.png";
import Aging from "../../assets/SVG/Aging.png";
import ForexSideBar from "../../components/Payment/ForexApproval/PaymentSideBar";
import FloatingProfileChecker from "../../components/Sourcing/FLoatingProfileChecker";

export default function PaymentMenu() {
  return (
    <ForexSideBar>
      <div className="supplier-menu-container">
        <NavLink to={"/payment/grossprofit"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Gross}
              style={{ width: 100, height: 100, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Gross Profitability
          </span>
        </NavLink>
        <NavLink to={"/payment/forexApproval"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={PaymentApproval}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Forex Approval
          </span>
        </NavLink>
        <NavLink to={"/payment/forexPlaning"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={ForexApproval}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Planning
          </span>
        </NavLink>
        <NavLink to={"/payment/supplierpayment"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={SupplierPayment}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Supplier Payment
          </span>
        </NavLink>
        <NavLink
          to={"/payment/paymentcollections"}
          className="payment-menu-card"
        >
          <div className="text-center">
            <img
              src={PaymentCollection}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Payment Collection
          </span>
        </NavLink>
        <NavLink to={"/payment/varianceanalysis"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={VarianceAnalysis}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Variance Analysis
          </span>
        </NavLink>
        <NavLink to={"/payment/prf"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Prf}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Payment Request Form
          </span>
        </NavLink>
        <NavLink to={"/payment/guarantee"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Guarantee}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Guarantee Preparation
          </span>
        </NavLink>
        <NavLink to={"/payment/costbuildup"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Cost}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Cost Build up
          </span>
        </NavLink>

        <NavLink to={"/payment/budget"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={PaymentApproval}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Budget
          </span>
        </NavLink>
        <NavLink to={"/payment/payable"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={payable}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Payable
          </span>
        </NavLink>
        <NavLink to={"/payment/receivable"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={receivable}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Receivable
          </span>
        </NavLink>
        <div
          className="payment-menu-card"
          style={{ background: "none", boxShadow: "none" }}
        ></div>
        <FloatingProfileChecker />
      </div>
    </ForexSideBar>
  );
}
