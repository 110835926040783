import React from "react";
import NavBar from "../../Layout/NavBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
export default function ClientIndex(props) {
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="ms-3">
          <Breadcrumb.Item className="prg" href="settings/clients">
            Clients
          </Breadcrumb.Item>

          {props.name == undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        <>{props.children}</>
      </div>
    </>
  );
}
