import React, { useState, useEffect, useMemo } from "react";
import {
  useGetGrossProfitSolutionBirrQuery,
  useGetSettingFiscalYearQuery,
} from "../../../features/SCMApi";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import GrossReportSolution from "./GrossReportSolution";
import SolutionAll from "./GrossProfitSolutionAll";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  Pagination,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const SupplierMenu = () => {
  const [year, setYear] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const { data: availableYears } = useGetSettingFiscalYearQuery();

  const { data: gross, refetch } = useGetGrossProfitSolutionBirrQuery({
    start,
    end,
  });

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(0); // State for managing active tab
  const PER_PAGE = 8;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleYearChange = (event) => {
    const selectedYearId = event.target.value;
    const selectedYearData = availableYears.data.find(
      (y) => y.id === selectedYearId
    );
    if (selectedYearData) {
      setYear(selectedYearData.name);
      setStart(selectedYearData.start_date);
      setEnd(selectedYearData.end_date);
    }
  };

  useEffect(() => {
    if (start && end) {
      refetch();
    }
  }, [start, end, refetch]);

  const filteredData = useMemo(() => {
    return (
      gross?.data?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items.sector_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
        return false;
      }) || []
    );
  }, [gross?.data, search]);

  const count = Math.ceil(filteredData.length / PER_PAGE);
  const _DATA = filteredData.slice((page - 1) * PER_PAGE, page * PER_PAGE);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const totals = useMemo(() => {
    return filteredData.reduce(
      (acc, item) => {
        acc.revenue += Number(item?.revenue || 0);
        acc.cost += Number(item?.cost || 0);
        acc.amount += Number(item?.actual_amount || 0);
        acc.grossProfit += Number(item?.revenue - item?.cost || 0);
        return acc;
      },
      { revenue: 0, cost: 0, grossProfit: 0, amount: 0 }
    );
  }, [filteredData]);

  useEffect(() => {
    // Fetch data or perform other operations if needed
  }, []);

  return (
    <ForexSideBar
      title={"Gross Profit"}
      name={"Solution"}
      navlink={`/payment/grossprofit`}
      handleSearchChange={handleSearchChange}
      placeholder={"Search Solution..."}
    >
      <Tabs
        value={activeTab}
        onChange={(event, newTab) => setActiveTab(newTab)}
        aria-label="tabs"
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Filtered Data" />
        <Tab label="All Data" />
      </Tabs>

      {activeTab === 0 && (
        <div>
          {filteredData.length > 0 && (
            <GrossReportSolution data={filteredData} />
          )}

          <div className="tool-header-container d-flex gap-3 justify-content-center align-items-center mb-2">
            <h5 className="logistics-batch-name">Please choose fiscal Year</h5>

            <FormControl sx={{ margin: "20px", minWidth: "200px" }}>
              <InputLabel>Fiscal Year</InputLabel>
              <Select value={year} onChange={handleYearChange}>
                {availableYears?.data?.map((availableYear) => (
                  <MenuItem key={availableYear.id} value={availableYear.id}>
                    {availableYear.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {year && start && end && (
              <div>
                <p>
                  Selected Year: {year}
                  <br></br>
                  Date Range: {new Date(start).toLocaleDateString()} -{" "}
                  {new Date(end).toLocaleDateString()}
                </p>
              </div>
            )}

            {gross?.length && <GrossReportSolution data={gross} />}
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="gross profit table">
              <TableHead>
                <TableRow>
                  <TableCell>Sector Name</TableCell>
                  <TableCell align="right">Revenue</TableCell>
                  <TableCell align="right">Actual Cost</TableCell>
                  {/* <TableCell align="right">Collected Amount</TableCell> */}
                  <TableCell align="right">Gross Profit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_DATA.map((items) => (
                  <TableRow
                    key={items.solution_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {items.solution_name || "N/A"}
                    </TableCell>
                    <TableCell align="right">
                      {items?.revenue
                        ? Number(items?.revenue).toLocaleString()
                        : "-"}
                    </TableCell>
                    <TableCell align="right">
                      {items?.cost ? Number(items?.cost).toLocaleString() : "-"}
                    </TableCell>
                    {/* <TableCell align="right">
                      {items?.actual_amount
                        ? Number(items?.actual_amount).toLocaleString()
                        : "-"}
                    </TableCell> */}
                    <TableCell align="right">
                      {items?.revenue - items?.cost
                        ? Number(items?.revenue - items?.cost).toLocaleString()
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={1} align="right">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.revenue.toLocaleString()}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.cost.toLocaleString()}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.amount.toLocaleString()}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.grossProfit.toLocaleString()}</strong>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          {count > 1 && (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "20px 0",
              }}
            />
          )}
        </div>
      )}

      {activeTab === 1 && <SolutionAll />}
    </ForexSideBar>
  );
};

export default SupplierMenu;
