import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentTermMutation,
  useGetCurrenciesQuery,
  useGetProjectQuery,
  useGetSettingBusinessUnitQuery,
  useGetProductsQuery,
} from "../../../features/SCMApi";
import DeleteIcon from "@mui/icons-material/Delete";

export default function PaymentTermModal(props) {
  const params = useParams();
  const [amount, setAmount] = useState(null);
  const [reason_for, setReasonFor] = useState(null);
  const [currency_id, setCurrencyId] = useState(null);
  const [category, setCategory] = useState(null);
  const [withHold, setWithHold] = useState(null);
  const [withHoldAmount, setWithHoldAmount] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [contratAmount, setContratAmount] = useState(null);

  const { data: currencies } = useGetCurrenciesQuery();
  const { data: solutions } = useGetProductsQuery();
  const { data: businessUnit } = useGetSettingBusinessUnitQuery();
  function HandleCancel() {
    props.modal(false);
  }
  const [formData, setFormData] = useState([
    {
      is_offshore: false,
      reason: reason_for,
      amount: amount,
      is_percentage: false,
      percent_amount: null,
      currency_id: currency_id,
      category: category,
      withHold: withHold,
      withHoldAmount: withHoldAmount,
      revenue: revenue,
      contratAmount: contratAmount,
    },
  ]);
  const addForm = () => {
    setFormData([
      ...formData,
      {
        is_offshore: false,
        reason: reason_for,
        amount: amount,
        is_percentage: false,
        percent_amount: null,
        currency_id: currency_id,
        category: category,
        withHold: withHold,
        revenue: revenue,
        contratAmount: contratAmount,
      },
    ]);
  };

  const handleisOffshoreChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].is_offshore = !newFormData[index].is_offshore;
    setFormData(newFormData);
  };
  const handlePercentageChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].is_percentage = !newFormData[index].is_percentage;
    setFormData(newFormData);
  };
  const handlePercentageAmountChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].percent_amount = event.target.value;
    setFormData(newFormData);
  };
  const handleAmountChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].contratAmount = event.target.value;
    newFormData[index].revenue = event.target.value * 0.85;

    setFormData(newFormData);
  };
  const handleReasonChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].reason = event.target.value;
    setFormData(newFormData);
  };
  const handleCurrencyChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].currency_id = event.target.value;
    setFormData(newFormData);
  };
  const handleCategoryChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].category = event.target.value;
    setFormData(newFormData);
  };
  const handleBusinessUnitChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].business_unit_id = event.target.value;
    setFormData(newFormData);
  };
  const handleSolutionChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].solution_id = event.target.value;
    setFormData(newFormData);
  };
  const handleWithhold = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].withHold = Number(event.target.value);
    newFormData[index].withHoldAmount =
      newFormData[index].revenue * Number(event.target.value);
    newFormData[index].amount =
      newFormData[index].contratAmount - newFormData[index].withHoldAmount;
    setFormData(newFormData);
  };

  const removeForm = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };
  let form = {
    project_id: params?.id,
    terms: formData,
  };
  const [CreatePaymentTerms] = useCreatePaymentTermMutation();
  function handleSubmit(e) {
    e.preventDefault();
    CreatePaymentTerms(form)
      .unwrap()
      .then((response) => {
        props.modal(false);
      })
      .then((error) => {});
  }

  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Add Payment Term</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            {formData?.map((item, index) => (
              <div className="d-flex align-items-center">
                <div className="border mb-2 py-2 px-2">
                  <div className="d-flex justify-content-center justify-content-center">
                    <div className="d-flex gap-2 px-2">
                      <input
                        type="checkbox"
                        className="stock-checkmark"
                        onChange={(e) => handleisOffshoreChange(e, index)}
                      />
                      <span className="h6">Off Shore</span>
                    </div>
                    <div className="d-flex gap-2 px-2">
                      <input
                        type="checkbox"
                        className="stock-checkmark"
                        onChange={(e) => handlePercentageChange(e, index)}
                      />
                      <span className="h6">Percentage</span>
                    </div>
                  </div>

                  <div className="order-supplier">
                    <div className="input-groups">
                      {formData[index]?.is_percentage == false ? (
                        <>
                          <div className="supplier-input-label">
                            Contrat Amount
                          </div>
                          <input
                            type="number"
                            className={`finance-input-field `}
                            required
                            onChange={(e) => handleAmountChange(e, index)}
                          />
                        </>
                      ) : (
                        <>
                          <div className="supplier-input-label">Percent</div>
                          <input
                            type="number"
                            className={`finance-input-field `}
                            required
                            onChange={(e) =>
                              handlePercentageAmountChange(e, index)
                            }
                          />
                        </>
                      )}
                      {formData[index]?.is_percentage == false ? (
                        <>
                          <div className="input-label">Withhold</div>
                          <select
                            required
                            name=""
                            id=""
                            className="finance-input-field"
                            onChange={(e) => handleWithhold(e, index)}
                          >
                            <option selected="true" disabled="disabled">
                              select withhold type
                            </option>
                            <option value={0.02}>2%</option>
                            <option value={0.095}>9.5%</option>
                            <option value={0}>With out withhold</option>
                          </select>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="supplier-input-label">Reason For</div>
                      <textarea
                        cols="30"
                        rows="5"
                        onChange={(e) => handleReasonChange(e, index)}
                        className="finance-input-field m-0"
                      ></textarea>

                      <div className="input-label">Currency</div>
                      <select
                        required
                        name=""
                        id=""
                        className="finance-input-field"
                        onChange={(e) => handleCurrencyChange(e, index)}
                      >
                        <option selected="true" disabled="disabled"></option>
                        {currencies?.data?.map((items) => (
                          <option value={items.id}>{items?.name}</option>
                        ))}
                      </select>
                      <div className="input-label">Category</div>
                      <select
                        required
                        name=""
                        id=""
                        className="finance-input-field"
                        onChange={(e) => handleCategoryChange(e, index)}
                      >
                        <option selected="true" disabled="disabled"></option>
                        <option value={"Product"}>Product</option>
                        <option value={"Service"}>Service</option>
                      </select>

                      <div className="input-label">Business Unit</div>
                      <select
                        name=""
                        id=""
                        required
                        className="finance-input-field "
                        onChange={(e) => handleBusinessUnitChange(e, index)}
                      >
                        <option selected="true" disabled="disabled">
                          Choose business unit
                        </option>

                        {businessUnit?.data?.map((items) => (
                          <option value={items.id}>{items?.name}</option>
                        ))}
                      </select>

                      <div className="input-label">Solutions</div>
                      <select
                        name=""
                        id=""
                        className="finance-input-field "
                        onChange={(e) => handleSolutionChange(e, index)}
                      >
                        <option selected="true" disabled="disabled">
                          Choose Solution
                        </option>
                        {solutions?.data?.map((items) => (
                          <option value={items.id}>{items?.abbrivation}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <DeleteIcon
                  onClick={() => removeForm(index)}
                  style={{ color: "red" }}
                />
              </div>
            ))}
            <div className="p-2">
              <button className="add-client-representative" onClick={addForm}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button type="submit" className={"form-save"}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
