import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const GrossReportBusinessUnit = ({ data }) => {
  const downloadExcel = async () => {
    if (!data || data.length === 0) {
      alert("No data to download.");
      return;
    }

    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();

    worksheet.mergeCells("A1:E1");

    const titleCell = worksheet.getCell("A1");

    titleCell.value = `IE ${formattedDate} Profitability Projection by Bussiness Unit`;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };

    titleCell.font = {
      name: "Times New Roman",
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",
      "Business Unit",
      "Revenue",
      "Actual Cost",
      "Gross Profit Amount",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;

    let count = 0;
    let totalRevenue = 0;
    let totalCost = 0;
    let totalGrossProfit = 0;

    data?.forEach((item) => {
      const revenue = item?.revenue || 0;
      const cost = item?.cost || 0;
      const grossProfit = revenue - cost;

      totalRevenue += revenue;
      totalCost += cost;
      totalGrossProfit += grossProfit;

      const dataRow = worksheet.addRow([
        ++count,
        item.business_unit_name || "-",
        revenue.toLocaleString(),
        cost.toLocaleString(),
        grossProfit.toLocaleString(),
      ]);

      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      });
    });

    // Add Footer Row
    const footerRow = worksheet.addRow([
      "Total",
      "",
      totalRevenue.toLocaleString(),
      totalCost.toLocaleString(),
      totalGrossProfit.toLocaleString(),
    ]);
    footerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
      };
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Gross_Report.xlsx");
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default GrossReportBusinessUnit;
