// function OrderTranings()
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  useDeleteOrderMutation,
  useGetFinalPriceByProjectQuery,
  useGetLocalBoqsQuery,
  useGetProjectItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectsQuery,
  useGetSuppliersQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import CreateOrder from "./CreateOrder";
import AddIcon from "@mui/icons-material/Add";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import NoRecord from "../common/NoRecord";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import { Pagination, Tooltip } from "@mui/material";
import ReactPaginate from "react-paginate";
import usePagination from "../Pagination/Pagination";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import LocalBoq from "./LocalBoq";

export default function OrderProduct(props) {
  const [createOrderModalToggle, setCreateOrderModalToggle] = useState(false);
  const [localMaterialBoq, setLocalMaterialBoq] = useState(false);
  useState(false);
  const [type, setType] = useState("");
  function handleCreateOrderModal(type) {
    setCreateOrderModalToggle(true);
    setType(type);
  }

  const params = useParams();
  const { data: projectdetail } = useGetProjectItemQuery(params.id);
  const { data: localBoq } = useGetLocalBoqsQuery(params.id);
  const order = [];
  order.push({ data: projectdetail?.data?.order });

  let componentData = projectdetail?.data?.order?.map((items) => items);

  const { data: responses } = useGetProjectsQuery();
  const Projectdata = [];

  responses?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  let avatar = [];
  const { data: projectmembers, isSuccess } = useGetProjectQuery(params.id);
  const { data: priceComparison } = useGetFinalPriceByProjectQuery(params.id);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(params.id);
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: suppliers } = useGetSuppliersQuery();

  const [deletedorder, setDeletedOrder] = useState();
  const [confirmation, setConfirmation] = useState(false);
  function confirmationHandler() {
    setConfirmation(true);
  }
  let deleted = {
    id: deletedorder?.id,
  };
  const [deleteorders, res] = useDeleteOrderMutation();
  const location = useLocation();
  const [timeOut, setTimeOut] = useState(true);
  const [filter, setFilter] = React.useState(false);
  const [order_type, setOrderType] = useState("");
  const [supplier, setSupplier] = useState("");
  function HandleFilter() {
    setFilter(!filter);
    setSupplier("");
    setOrderType("");
  }
  setTimeout(() => {
    setTimeOut(false);
  }, 3000);

  const [search, setSearch] = useState("");

  const filteredData = (supplier) => {
    return componentData
      ?.filter((items) => {
        if (supplier === "") {
          return items;
        } else if (items?.supplier_id == supplier) {
          return items;
        }
      })
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items.order_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      .filter((items) => {
        // Manually filter based on order_type === 'Product'
        return items.order_type === "Product";
      })
      .map((items) => items);
  };

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 12;

  const count = Math.ceil(
    filteredData(supplier, order_type)?.length / PER_PAGE
  );
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const _DATA = usePagination(filteredData(supplier, order_type), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const [message, setMessage] = useState(null);
  return (
    <SourcingIndex
      SourcingIndex
      name={Projectdata[0]?.project_name}
      names="Orders"
      handleSearchChange={handleSearchChange}
      placeholder={"search Order..."}
      Message={message}
    >
      {" "}
      {CheckProjectPermission(
        "create_order",
        projectmembers,
        userinfo,
        roleRoles
      ) ? (
        <div className="d-flex gap-2">
          <div
            className="create-project-btn mt-3"
            onClick={() => handleCreateOrderModal("Product")}
          >
            <AddIcon />
            Create Order
          </div>
          <NavLink
            className="create-project-btn mt-3"
            to={`/sourcing/paStage/${params?.id}`}
          >
            Pa Stage
          </NavLink>
          {priceComparison?.data?.length ? (
            <NavLink
              className="create-project-btn mt-3"
              to={`/sourcing/comparedprices/${params?.id}`}
            >
              Compared Prices
            </NavLink>
          ) : (
            ""
          )}
          {localBoq?.boq?.length ? (
            <button
              className="create-project-btn mt-3"
              onClick={() => setLocalMaterialBoq(true)}
            >
              Local Materials
            </button>
          ) : (
            ""
          )}
          <div className="d-flex gap-4 ms-5 align-items-center ">
            {filter ? (
              <Tooltip title="Filter On">
                <FilterAltIcon
                  onClick={HandleFilter}
                  style={{ fontSize: "32px", color: "#064b4f" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Filter Off">
                <FilterAltOffIcon
                  onClick={HandleFilter}
                  style={{ fontSize: "32px", color: "#064b4f" }}
                />
              </Tooltip>
            )}
            {filter ? (
              <>
                <select
                  className="select-option-create-order border"
                  onChange={(e) => setSupplier(e.target.value)}
                >
                  <option value="" selected>
                    Supplier
                  </option>
                  {suppliers?.data?.map((items) => (
                    <option value={items?.id}>{items?.distributor_name}</option>
                  ))}
                </select>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {componentData?.length !== 0 ? (
        <div className="order-card-container mt-3">
          {_DATA.currentData()?.map((dada) => (
            <div className="order-card">
              <NavLink
                to={`/sourcing/orderdetail/${dada.id}`}
                state={{
                  names: dada?.order_name,
                  title: dada?.project?.project_name,

                  navlink: `/sourcing/orders/${params.id}`,
                }}
              >
                <div className="order-information-container"></div>
                <div className="order-info">
                  <div className="order-project">
                    <div>
                      <Tooltip title={dada?.supplier?.distributor_name}>
                        {dada?.supplier_id != null ? (
                          <div className="project-name">
                            {dada?.supplier?.distributor_name?.length >= 19
                              ? dada?.supplier?.distributor_name?.slice(0, 20) +
                                "..."
                              : dada?.supplier?.distributor_name}
                          </div>
                        ) : (
                          <div className="project-name">
                            {dada?.bill_to?.distributor_name?.length >= 19
                              ? dada?.bill_to?.distributor_name?.slice(0, 20) +
                                "..."
                              : dada?.bill_to?.distributor_name}
                          </div>
                        )}
                      </Tooltip>
                      <div className="project-label">
                        {dada?.supplier_id != null ? "Supplier" : "Bill to"}
                      </div>
                    </div>

                    <div>
                      <div className="project-name">{dada?.order_type}</div>
                      <div className="project-label">Order Type</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </NavLink>
              <div className="order-supplier">
                <Tooltip title={dada?.order_name}>
                  <div className="order-supplier">
                    {dada?.order_name?.length >= 60
                      ? dada?.order_name?.slice(0, 60) + "..."
                      : dada?.order_name}
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
      <div className="mt-3">
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
      {createOrderModalToggle ? (
        <CreateOrder
          modal={setCreateOrderModalToggle}
          setMessage={setMessage}
          type={type}
        />
      ) : (
        <></>
      )}
      {localMaterialBoq && (
        <LocalBoq data={localBoq} modal={setLocalMaterialBoq} />
      )}
    </SourcingIndex>
  );
}
