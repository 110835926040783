import React, { useState } from "react";
import "../../pages/Payment/Payable/aging.css";
import { useEditFiscalYearMutation } from "../../features/SCMApi";

const EditFiscalYearModal = ({ name, start_date, end_date, id, onClose }) => {
  const [editedName, setEditedName] = useState(name);
  const [editedStartDate, setEditedStartDate] = useState(start_date);
  const [editedEndDate, setEditedEndDate] = useState(end_date);
  const [editFiscalYear, res] = useEditFiscalYearMutation();

  let payload = {
    id: id,
    name: editedName,
    start_date: editedStartDate,
    end_date: editedEndDate,
  };

  const onUpdate = () => {
    editFiscalYear(payload)
      .unwrap()
      .then((response) => {
        onClose();
        console.log("response for success: ", response);
      })
      .then((error) => {
        console.log("error: ", error);
      });
  };

  return (
    <div className="modal-container" show={true} onHide={onClose}>
      <div className="modal-dialog  modal-dialog-centered" role="document">
        <div className="modal-content" style={{ minHeight: "0" }}>
          <div className="modal-header">
            <h5 className="modal-title">Edit Fiscal Year</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>

          <div className="modal-body">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                aria-label="Fiscal Year Name"
                placeholder="Enter Fiscal Year"
                defaultValue={editedName}
                onChange={(e) => setEditedName(e.target.value)}
              />
            </div>

            <div className="input-group mb-3">
              <input
                type="date"
                className="form-control"
                aria-label="Start Date"
                defaultValue={editedStartDate}
                onChange={(e) => setEditedStartDate(e.target.value)}
              />
            </div>

            <div className="input-group mb-3">
              <input
                type="date"
                className="form-control"
                aria-label="End Date"
                defaultValue={editedEndDate}
                onChange={(e) => setEditedEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={onClose}
              type="button"
              className="form-cancel"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() => onUpdate()}
              type="button"
              className="form-save"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFiscalYearModal;
