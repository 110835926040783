import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import "../forex.style.css";
import InitiateForexModal from "./Modals/InitiateForexModal";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AddNewSupplier from ".././ForexPlanning/Modal/AddNewSupplier";
import SuccessMessage from "../SuccessMessage";

const InitiateForexSupplierButton = (props) => {
  //Initiate Forex Modal state
  const [initiateForexModal, setInitiateForexModal] = React.useState(false);
  const [modalName, setName] = React.useState(null);

  //Initiate Forex Modal handlers
  function handleInitiateForexModal() {
    setInitiateForexModal(true);
  }

  const [addNewModal, setAddNewModal] = React.useState(false);
  function AddNewModalHandler() {
    setAddNewModal(true);
  }
  const handleAddNewButtonClick = (e, name) => {
    return AddNewModalHandler();
  };

  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  return (
    <>
      {successMessage === true ? (
        <>
          <SuccessMessage
            style={{
              marginTop: "10px",
              width: "135%",
              marginLeft: "-125px",
              backgroundColor: "#52ffab96",
            }}
            Message={message}
          />
        </>
      ) : (
        <>
          <Button
            sx={{
              minWidth: props?.style?.width ? props?.style?.width : "163px",
              borderRadius: props?.style?.borderRadius
                ? props?.style?.borderRadius
                : "25px",
              height: props?.style?.height,
              backgroundColor: "#064b4f",
              "&:hover": {
                backgroundColor: "#186569",
              },
            }}
            onClick={(e) =>
              props.name === "InitiateforexIe" ? (
                (handleInitiateForexModal(), setName("InitiateforexIe"))
              ) : props.name === "InitiateforexClient" ? (
                (handleInitiateForexModal(), setName("InitiateforexClient"))
              ) : props.name === "addNew" ? (
                handleAddNewButtonClick()
              ) : props?.name === "pi" ? (
                props?.downloadSummarizedPi()
              ) : (
                // props?.onClick
                <></>
              )
            }
          >
            <span
              className="initiateForex-button"
              style={{
                marginBottom: props?.style?.iconMargin,
                fontSize: props?.style?.fontSize,
              }}
            >
              {props?.name === "pi" ? (
                <SaveAltIcon
                  sx={{
                    fontSize: "20px",
                    marginTop: "-5px",
                    marginRight: "5px",
                  }}
                />
              ) : (
                <AddIcon
                  sx={{
                    fontSize: props?.style?.iconFont
                      ? props?.style?.iconFont
                      : "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              {props?.title}
            </span>
          </Button>
        </>
      )}
      {initiateForexModal ? (
        <InitiateForexModal
          modal={setInitiateForexModal}
          name={modalName}
          initiatedBy={props?.name}
          clientData={props?.projectData}
          projectIe={props?.name === "InitiateforexIe" && props?.projectIe}
          bank={props?.name === "InitiateforexIe" && props?.bank}
          // refreshe={useGetSettingClientQuery}
          successMessage={setSuccessMessage}
          message={setMessage}
        />
      ) : (
        <></>
      )}
      {addNewModal ? (
        <AddNewSupplier
          modal={setAddNewModal}
          successMessage={props?.successMessage}
          message={props?.message}
          // refreshe={useGetSettingClientQuery}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default InitiateForexSupplierButton;
