import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { NavLink } from "react-router-dom";
import startup from "../../../assets/SVG/startup.png";
import financialReport from "../../../assets/SVG/financialReport.png";
import solutionpr from "../../../assets/SVG/solutionpr.png";
import sectorpro from "../../../assets/SVG/sectorpro.png";

const GrossProfitIndex = () => {
  return (
    <ForexSideBar
      title={"Gross Profit"}
      name={"Product"}
      navlink={`/payment/grossprofit`}
    >
      <div className="supplier-menu-container">
        <NavLink
          to={"/payment/grossprofit/Project"}
          className="payment-menu-card"
        >
          <div className="text-center">
            <img
              src={startup}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Gross Profit by Project
          </span>
        </NavLink>
        <NavLink
          to={"/payment/grossprofit/Sector"}
          className="payment-menu-card"
        >
          <div className="text-center">
            <img
              src={solutionpr}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Gross Profit by Sector
          </span>
        </NavLink>
        <NavLink
          to={"/payment/grossprofit/BusinessUnit"}
          className="payment-menu-card"
        >
          <div className="text-center">
            <img
              src={sectorpro}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Gross Profit by Business Unit
          </span>
        </NavLink>
        <NavLink
          to={"/payment/grossprofit/Solution"}
          className="payment-menu-card"
        >
          <div className="text-center">
            <img
              src={financialReport}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Gross Profit by Solution
          </span>
        </NavLink>
      </div>{" "}
    </ForexSideBar>
  );
};

export default GrossProfitIndex;
