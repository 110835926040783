import React, { useEffect, useMemo, useState } from "react";
import "../forex.style.css";
import {
  Pagination,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  Tab,
  Tabs,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useGetGrossProfitBirrQuery,
  useGetSettingFiscalYearQuery,
} from "../../../features/SCMApi";
import usePagination from "../../Pagination/Pagination";
import GrossReport from "./GrossReport";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";
import ProjectAll from "./GrossProfitProductAll";

export default function GrossProfitProduct() {
  const [year, setYear] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const { data: availableYears } = useGetSettingFiscalYearQuery();
  const { data: gross, refetch } = useGetGrossProfitBirrQuery(
    { start, end },
    { skip: !start || !end }
  );

  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleYearChange = (event) => {
    const selectedYearId = event.target.value;
    const selectedYearData = availableYears.data.find(
      (y) => y.id === selectedYearId
    );
    if (selectedYearData) {
      setYear(selectedYearData.name);
      setStart(selectedYearData.start_date);
      setEnd(selectedYearData.end_date);
    }
  };

  useEffect(() => {
    if (start && end) {
      refetch();
    }
  }, [start, end, refetch]);

  const filteredData = useMemo(() => {
    return (
      gross?.data?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items.project?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
        return false;
      }) || []
    );
  }, [gross?.data, search]);

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 8;
  const [activeTab, setActiveTab] = useState(0);

  const count = Math.ceil(filteredData.length / PER_PAGE);
  const _DATA = usePagination(filteredData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const [Items, setItems] = useState("all");
  let grossData = gross?.data?.filter(
    (items) => items?.gross_profit?.length !== 0
  );

  let TotalRevenueAmount = 0;
  gross?.data?.forEach((items) => {
    if (items?.gross_profit?.length > 0) {
      TotalRevenueAmount += Number(items.gross_profit[0].revenue) || 0;
    }
  });

  const totals = useMemo(() => {
    return filteredData.reduce(
      (acc, item) => {
        if (item.gross_profit && item.gross_profit.length > 0) {
          const grossProfit = item.gross_profit[0];
          acc.revenue += Number(grossProfit.revenue) || 0;
          acc.cost += Number(grossProfit.cost) || 0;
          acc.gross_profit += Number(grossProfit.gross_profit) || 0;
        }
        return acc;
      },
      { revenue: 0, cost: 0, gross_profit: 0 }
    );
  }, [filteredData]);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [project_id, setProjectId] = useState("");

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setProjectId(item.project_id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setProjectId(null);
  };

  useEffect(() => {
    if (project_id && start && end) {
      axios
        .get(
          `${API_BASE_URL}/scm/get-prf-revenue/${project_id}?startDate=${start}&endDate=${end}`
        )
        .then((response) => {
          setData(response?.data);
        })
        .catch((error) => {});
    }
  }, [project_id, start, end]);

  return (
    <ForexSideBar
      title={"Gross Profit"}
      name={"Product"}
      navlink={`/payment/grossprofit`}
      handleSearchChange={handleSearchChange}
      placeholder={"search Project..."}
    >
      <Tabs
        value={activeTab}
        onChange={(event, newTab) => setActiveTab(newTab)}
        aria-label="tabs"
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Filtered Data" />
        <Tab label="All Data" />
      </Tabs>

      {activeTab === 0 && (
        <div>
          <div className="tool-header-container d-flex gap-3 justify-content-center align-items-center mb-2">
            <h5 className="logistics-batch-name">Please choose fiscal Year</h5>

            <FormControl sx={{ margin: "20px", minWidth: "200px" }}>
              <InputLabel>Fiscal Year</InputLabel>
              <Select value={year} onChange={handleYearChange}>
                {availableYears?.data?.map((availableYear) => (
                  <MenuItem key={availableYear.id} value={availableYear.id}>
                    {availableYear.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {year && start && end && (
              <div>
                <p>
                  Selected Year: {year}
                  <br></br>
                  Date Range: {new Date(start).toLocaleDateString()} -{" "}
                  {new Date(end).toLocaleDateString()}
                </p>
              </div>
            )}

            {grossData?.length && <GrossReport data={grossData} />}
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="gross profit table">
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Revenue</TableCell>
                  <TableCell>Actual Cost</TableCell>
                  <TableCell>Gross Profit</TableCell>
                  <TableCell>GPP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_DATA?.currentData()?.map((items) => (
                  <TableRow
                    key={items?.project_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => handleCardClick(items?.project_id)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      <Tooltip title={items?.project}>
                        <span>
                          {items?.project?.length > 20
                            ? items?.project.slice(0, 20) + "..."
                            : items?.project}
                        </span>
                      </Tooltip>
                    </TableCell>
                    {items?.gross_profit?.length !== 0 ? (
                      items?.gross_profit?.map((gross) => (
                        <React.Fragment key={gross?.currency}>
                          <TableCell>
                            {(gross?.revenue || "-").toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {(gross?.cost || "-").toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {(gross?.gross_profit || "-").toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {TotalRevenueAmount
                              ? Number(
                                  (gross?.revenue / TotalRevenueAmount) * 100
                                )?.toLocaleString()
                              : 0}
                            %
                          </TableCell>
                        </React.Fragment>
                      ))
                    ) : (
                      <>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={1} align="right">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.revenue.toLocaleString()}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.cost.toLocaleString()}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.gross_profit.toLocaleString()}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {totals.revenue
                        ? (
                            (totals.gross_profit / totals.revenue) *
                            100
                          ).toLocaleString()
                        : 0}
                      %
                    </strong>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          {count > 1 && (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          )}
        </div>
      )}

      {activeTab === 1 && <ProjectAll />}
    </ForexSideBar>
  );
}
