import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import BalancePage from "./BalancePage";
import "../forex.style.css";
import {
  useGetForexReceivableQuery,
  useGetForexNeedsQuery,
  useGetUserInfoQuery,
  useGetForexSupplierReceivableQuery,
} from "../../../features/SCMApi";
import GapAnalysisDisplay from "./GapAnalysisDisplay";
import CheckPermission from "../../common/checkPermission/checkPermission";
import ForexNeedDisplay from "./ForexNeedDisplay";
import ReceivableDisplay from "./ReceivableDisplay";
import ReceivableSupplierDisplay from "./ReceivableSupplierDisplay";
import HistoryDisplay from "./HistoryDisplay";
import EmployeeReceivable from "./EmployeeReceivable";
import { useNavigate, useParams } from "react-router-dom";

export default function ForexApprovalNavBar() {
  const { data: receivableData } = useGetForexReceivableQuery();
  const { data: receivableSupplierData } = useGetForexSupplierReceivableQuery();
  const { data: forexNeedData } = useGetForexNeedsQuery();
  const { data: userinfo } = useGetUserInfoQuery();
  const navigate = useNavigate();
  const params = useParams();
  const { tabKey } = useParams();

  const handleTabChange = (key) => {
    navigate(`./${key}`);
  };
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="gap-analysis-web-view">
        <Tabs
          defaultActiveKey="forexNeed"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border"
        >
          <Tab eventKey="forexNeed" title="Forex Need">
            <ForexNeedDisplay data={forexNeedData} />
          </Tab>

          <Tab
            eventKey="VendorReceivables"
            title="Vendor Receivables"
            disabled={!CheckPermission("view_receivable", userinfo)}
          >
            <ReceivableDisplay data={receivableData?.data} />
          </Tab>
          <Tab
            eventKey="SupplierReceivables"
            title="Supplier Receivables"
            disabled={!CheckPermission("view_receivable", userinfo)}
          >
            <ReceivableSupplierDisplay data={receivableSupplierData?.data} />
          </Tab>

          <Tab
            eventKey="Employee Receivable"
            title="Employee Receivable"
            disabled={!CheckPermission("view_employee", userinfo)}
          >
            <EmployeeReceivable data={receivableData?.data} />
          </Tab>

          <Tab
            eventKey="balance"
            title="Balance"
            disabled={CheckPermission("view_balance", userinfo) ? false : true}
          >
            <BalancePage />
          </Tab>

          <Tab
            eventKey="gapAnalysis"
            title="Gap Analysis"
            disabled={
              CheckPermission("view_gap_analysis", userinfo) ? false : true
            }
          >
            <GapAnalysisDisplay />
          </Tab>

          <Tab
            eventKey="history"
            title="History"
            disabled={
              CheckPermission("view_gap_analysis", userinfo) ? false : true
            }
          >
            <HistoryDisplay data={forexNeedData} />
          </Tab>
        </Tabs>
      </div>

      <div className="gap-analysis-mobile-view">
        <Tabs
          defaultActiveKey="forexNeed"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forex-receivable-mobile"
        >
          <Tab eventKey="forexNeed" title="Forex Need">
            <ForexNeedDisplay data={forexNeedData} />
          </Tab>
          <Tab
            eventKey="Receivables"
            title="Receivables"
            className=""
            disabled={
              CheckPermission("view_receivable", userinfo) ? false : true
            }
          >
            <ReceivableDisplay data={receivableData?.data} />
          </Tab>
          <Tab
            eventKey="SupplierReceivables"
            title="Supplier Receivables"
            disabled={CheckPermission("view_receivable", userinfo)}
          >
            <ReceivableSupplierDisplay data={receivableSupplierData?.data} />
          </Tab>
          <Tab
            eventKey="employee"
            title="Employee Receivable"
            className=""
            disabled={CheckPermission("view_balance", userinfo) ? false : true}
          >
            <EmployeeReceivable data={receivableData?.data} />
          </Tab>

          <Tab
            eventKey="balance"
            title="Balance"
            className=""
            disabled={CheckPermission("view_balance", userinfo) ? false : true}
          >
            <BalancePage />
          </Tab>

          <Tab
            eventKey="gapAnalysis"
            title="Gap Analysis"
            className=""
            disabled={
              CheckPermission("view_gap_analysis", userinfo) ? false : true
            }
          >
            <GapAnalysisDisplay />
          </Tab>
          <Tab
            eventKey="history"
            title="History"
            className=""
            disabled={
              CheckPermission("view_gap_analysis", userinfo) ? false : true
            }
          >
            <HistoryDisplay data={forexNeedData} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
