import React from "react";
import NavBar from "../../../Layout/NavBar";
import { Breadcrumb } from "react-bootstrap";

export default function FiscalYearIndex(props) {
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="ms-3">
          <Breadcrumb.Item className="prg" href="settings/fiscal-year">
            Fiscal Year
          </Breadcrumb.Item>
          {props.name == undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        <>{props.children}</>
      </div>
    </>
  );
}
