import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { IoMdCloudDownload, IoMdPrint } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import usePagination from "../../components/Pagination/Pagination";
import NoRecord from "../../components/common/NoRecord";
import { date } from "../../components/dateFormator";
const DataTable = ({
  open,
  data,
  selectedItem,
  setSelectedItem,
  setShowComponent,
  setOriginalItems,
}) => {
  const [shouldPrintGrn, setShouldPrintGrn] = useState(false);
  const navigate = useNavigate();
  const itemsPerPage = 5;
  ///////////////////////////   pagination ////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage; //20
  const indexOfFirstItem = indexOfLastItem - itemsPerPage; ///0
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  ///////////////////////////   pagination ////////////////

  const handleRowClick = (item) => {
    // Logic to handle click on a row, 'item' contains the data of the clicked row
    setSelectedItem(item);
    setOriginalItems(item);
    setShowComponent(true);
  };
  const handleDownloadGrn = (selectedItemID) => {
    navigate(`/download/grn-pdf/${selectedItemID}`);
  };
  const handlePrintGrn = (selectedItemID) => {
    navigate(`/download/grn-pdf/${selectedItemID}`);
  };
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(data?.length / PER_PAGE);

  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <>
      {open && (
        <div style={{ backgroundColor: "#e3e5e8" }}>
          {_DATA?.currentData()?.length ? (
            <table className="table table-striped mb-0">
              <thead
                className=""
                style={{
                  backgroundColor: "#186569",
                  textAlign: "center",
                  height: "40px",
                  color: "white",
                }}
              >
                <th style={{ width: "12.5%", fontSize: "12px" }}>PO No</th>
                <th style={{ width: "12.5%", fontSize: "12px" }}>PRF No</th>
                <th style={{ width: "12.5%", fontSize: "12px" }}>Position</th>
                <th style={{ width: "12.5%", fontSize: "12px" }}>
                  Purchased By
                </th>
                <th style={{ width: "12.5%", fontSize: "12px" }}>
                  Requested By
                </th>
                <th style={{ width: "16.5%", fontSize: "12px" }}>
                  Department or Project
                </th>
                <th style={{ width: "12.5%", fontSize: "12px" }}>
                  Requested Date
                </th>
                <th style={{ width: "12.5%", fontSize: "12px" }}>Actions</th>
                {/* Add other table headings based on your data */}
              </thead>

              <tbody className="p-10 rounded  task-order-name">
                {_DATA?.currentData()?.map((item) => {
                  return (
                    <tr
                      className="hover:bg-gray-200"
                      style={{
                        minHeight: "120px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                      key={item?.id}
                      onClick={() => handleRowClick(item)}
                    >
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.PO_no}
                      </td>
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.PRF_no}
                      </td>
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.Position}
                      </td>
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.purchased_by}
                      </td>
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.requested_by}
                      </td>
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.department_or_project}
                      </td>
                      <td style={{ width: "12.5%", fontSize: "11px" }}>
                        {item?.requested_date
                          ? date(item?.requested_date)
                          : "-"}
                      </td>
                      <td>
                        <div className="d-flex fs-3 justify-content-center gap-4">
                          {item?.optional_flag === 1 && (
                            <>
                              <IoMdCloudDownload
                                size={24}
                                title="download grn"
                                style={{ color: "#186569" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleDownloadGrn(item?.id);
                                }}
                              />{" "}
                              <IoMdPrint
                                size={24}
                                style={{ color: "#186569" }}
                                title="print grn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handlePrintGrn(item?.id);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>
                      {/* Add more table cells for other properties */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <NoRecord />
          )}
          <div className="d-flex justify-content-end fs-4 text-black ">
            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "5px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DataTable;
