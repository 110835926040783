import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Select from "../../ForexApproval/Select";
import MultipleTextInput from "../MultipleTextInput";
import {
  useGetReceivableManufacturersQuery,
  useGetForexOpeningToQuery,
  useGetReceivableBanksQuery,
  useAddForexSupplierPlanningReceivableMutation,
  useGetSuppliersOrdersQuery,
} from "../../../../features/SCMApi";
import SelectOption from "../../../common/SelectOption";

export default function AddNewSupplier(props) {
  const [addForexReceivable] = useAddForexSupplierPlanningReceivableMutation();

  const { data: vendorData } = useGetReceivableManufacturersQuery();
  const { data: supplierData } = useGetForexOpeningToQuery();
  const { data: bankData } = useGetReceivableBanksQuery();

  const [descriptionText, setDescriptionText] = React.useState(null);
  const [vendorName, setVendorName] = useState(null);
  const [currencyName, setCurrencyName] = useState(null);
  const [expectedDate, setExpectedDate] = useState(null);
  const [productServiceName, setProductService] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [supplierName, setSupplierName] = useState(null);
  const [amount, setAmountName] = useState(null);
  const [isSelected, setIsSelected] = React.useState(null);
  const [currency, setCurrency] = useState([]);
  // const [isChecked, setIsChecked] = useState(false);

  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  const [supplierID, setSupplier] = useState(null);
  const list = [];
  Suppliers?.data?.map((items) => {
    list.push({
      value: items?.id,
      label: items?.distributor_name,
    });
  });

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/currencies`)
      .then((response) => {
        setCurrency(response.data.data);
      })
      .catch((err) => {
        console.error("error", err);
      });
  }, []);

  let params = {
    description: descriptionText,
    order_type: productServiceName,
    supplier: supplierData?.data
      ?.filter((supplier) => supplier?.distributor_name === vendorName)
      .map((id) => id?.id)
      .toString(),
    currency_id: currency
      ?.filter((currenc) => currenc?.code === currencyName)
      .map((id) => id?.currency_id)
      .flat()
      .toString(),
    expected_date: expectedDate,
    payment_way: isSelected,
    bank_id:
      bankName !== null
        ? bankData?.data
            ?.filter((bank) => bank?.bank_name === bankName)
            .map((id) => id?.id)
            .flat()
            .toString()
        : null,
    supplier_id:
      supplierID?.value !== null
        ? list
            ?.filter((supplier) => supplier?.value === supplierID?.value)
            .map((id) => id?.value)
            .flat()
            .toString()
        : null,
    amount: Number(amount),
    // is_LLC: isChecked,
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    addForexReceivable(params)
      .unwrap()
      .then((response) => {
        props?.successMessage(true);
        props?.message("forex receivable created successfully!!!");
      })
      .catch((error) => {});
  };

  const currencys = currency?.map((currenc) => currenc?.code);
  const productService = [
    "Product ",
    "Service",
    "Training",
    "MDF",
    "Rebate",
    "Credit Note",
  ];
  const wayOfPayment = ["To Bank", "To Supplier"];
  const banks = bankData?.data?.map((bank) => bank?.bank_name);
  const suppliers = supplierData?.data?.map(
    (supplier) => supplier?.distributor_name
  );
  // const handleSelect = (isSelected) => {
  //   isSelected !== "To Bank"
  //     ? setBankName(null)
  //     : isSelected !== "To Supplier"
  //     ? setSupplierName(null)
  //     : "";
  // };
  // handleSelect(isSelected);

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="AddNew">Add New</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {/* <div className="check-box-field m-3">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <div className="prg-pri">Is LLC</div>
                </div> */}
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Supplier
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Currency
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "4px" }}
                      >
                        Product/Service
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Description
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{
                          marginTop: "80px",
                        }}
                      >
                        Amount
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Way of Payment
                      </div>
                      {isSelected === "To Bank" ? (
                        <div
                          className="rmaFileInputLabel"
                          style={{ marginTop: "5px" }}
                        >
                          Bank
                        </div>
                      ) : (
                        <></>
                      )}
                      {isSelected === "To Supplier" ? (
                        <div
                          className="rmaFileInputLabel"
                          style={{ marginTop: "5px" }}
                        >
                          Supplier
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Expected Date
                      </div>
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <Select
                          name={suppliers}
                          placeHolder={"Select suppliers..."}
                          onChange={setVendorName}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={currencys}
                          placeHolder={"Select Currency..."}
                          onChange={setCurrencyName}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={productService}
                          placeHolder={"Select Product/Service..."}
                          onChange={setProductService}
                        />
                      </div>
                      <div style={{ width: "110%", marginLeft: "20px" }}>
                        <MultipleTextInput setText={setDescriptionText} />
                      </div>
                      <div>
                        <input
                          required
                          className="forex-select-option"
                          placeholder="Amount"
                          onChange={(event) =>
                            setAmountName(event.target.value)
                          }
                        />
                      </div>
                      <div>
                        <Select
                          name={wayOfPayment}
                          placeHolder={"Select Way of payment..."}
                          onChange={setIsSelected}
                          required={true}
                        />
                      </div>
                      {isSelected === "To Bank" ? (
                        <div>
                          <Select
                            name={banks}
                            placeHolder={"Select Bank..."}
                            onChange={setBankName}
                            required={true}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {isSelected === "To Supplier" ? (
                        <div>
                          {/* <Select
                            name={suppliers}
                            onChange={setSupplierName}
                            placeHolder={"Select Supplier..."}
                            required={true}
                          /> */}
                          <SelectOption
                            options={list}
                            setters={setSupplier}
                            value={supplierID}
                            isinput={true}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter expected date"
                          required
                          onChange={(e) => setExpectedDate(e.target.value)}
                        />
                      </div>
                      {/* <div>
                        <DatePicker
                          onChange={setExpectedDate}
                          passedValue={""}
                          required={true}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
