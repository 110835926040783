import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useDeleteSectorMutation } from "../../features/SCMApi";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteSectorModal({
  show = false,
  handleDeleteModal,
  refresh,
  clientId,
  name,
  solutionName,
}) {
  const [DeleteSector] = useDeleteSectorMutation();
  const params = {
    id: clientId,
  };
  const handleSubmit = (e) => {
    // alert(clientId);
    // console.log(params, "params");
    e.preventDefault();
    DeleteSector(params)
      .unwrap()
      .then((response) => {
        handleDeleteModal(false);
        refresh();
      })
      .catch(function (response) {});
  };

  return (
    <BootstrapDialog
      onClose={handleDeleteModal}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "16px",
                color: "#575757",
                // marginBottom: "4%",
                paddingTop: "15px ",
              }}
            >
              Do you want to delete{" "}
              {name === "solution" ? `${solutionName}` : null} ?
            </p>

            <br />
            <br />
          </Grid>
        </Grid>
        {/* <DialogActions sx={{ display: "inline-block", marginLeft: "29%" }}>
          <Button
            variant="contained"
            type="reset"
            sx={{
              backgroundColor: "#fff",
              color: "#575757",
              borderRadius: "25px",
              "&:hover": {
                backgroundColor: "#ececec",
                color: "#575757",
              },
              width: "84.5px",
              marginTop: "-30px",
            }}
            onClick={handleDeleteModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              borderRadius: "25px",
              width: "84.5x",
              backgroundColor: "#FF5252",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#b12323",
                color: "#fff",
              },
              marginTop: "-30px",
            }}
          >
            Delete
          </Button>
        </DialogActions> */}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px", // Adds spacing between buttons
            marginTop: "16px", // Optional margin for spacing the buttons from content above
          }}
        >
          <Button
            variant="contained"
            type="reset"
            sx={{
              backgroundColor: "#fff",
              color: "#575757",
              borderRadius: "25px",
              "&:hover": {
                backgroundColor: "#ececec",
                color: "#575757",
              },
              width: "84.5px",
            }}
            onClick={handleDeleteModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              borderRadius: "25px",
              width: "84.5px",
              backgroundColor: "#FF5252",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#b12323",
                color: "#fff",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </BootstrapDialog>
  );
}
