import React, { useState } from "react";
import FiscalYearIndex from "../../pages/Settings/fiscalYear/FiscalYearIndex";
import { useGetSettingFiscalYearQuery } from "../../features/SCMApi";
import usePagination from "../Pagination/Pagination";
import NoRecord from "../common/NoRecord";
import { IconButton, Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Card from "react-bootstrap/Card";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditFiscalYearModal from "./EditFiscalYearModal";
import DeleteFiscalYearModal from "./DeleteFiscalYearModal";
import CreateFiscalYear from "./CreateFiscalYear";

const FiscalYear = () => {
  const {
    data: availableYears,
    isLoading,
    isError,
  } = useGetSettingFiscalYearQuery();

  const [fiscalYearId, setFiscalYearId] = useState(null);
  const [fiscalYearName, setFiscalYearName] = useState(null);
  const [fiscalYearStartDate, setFiscalYearStartDate] = useState(null);
  const [fiscalYearEndDate, setFiscalYearEndDate] = useState(null);
  const [createFiscalYearModal, setCreatefiscalYear] = useState(false);
  const [showFiscalYearDeleteModal, toggleFiscalYearDeleteModal] =
    useState(false);
  const [showFiscalYearEditModal, toggleFiscalYearEditModal] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const PER_PAGE = 5;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = availableYears?.data?.filter((info) =>
    info?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const count = filteredData ? Math.ceil(filteredData.length / PER_PAGE) : 0;
  const _DATA = usePagination(filteredData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleCreateFiscalYear = () => {
    setCreatefiscalYear(true);
  };
  const handleCloseCreate = () => {
    setCreatefiscalYear(false);
  };

  const handleDeleteFiscalYear = (id) => {
    setFiscalYearId(id);
    toggleFiscalYearDeleteModal(!showFiscalYearDeleteModal);
  };
  const handleCloseDelete = (id) => {
    setFiscalYearId(null);
    toggleFiscalYearDeleteModal(!showFiscalYearDeleteModal);
  };
  const handleEditFiscalYear = (data) => {
    setFiscalYearId(data?.id);
    setFiscalYearName(data?.name);
    setFiscalYearStartDate(data?.start_date);
    setFiscalYearEndDate(data?.end_date);
    toggleFiscalYearEditModal(!showFiscalYearEditModal);
  };
  const handleClose = () => {
    setFiscalYearId(null);
    setFiscalYearName(null);
    setFiscalYearStartDate(null);
    setFiscalYearEndDate(null);
    toggleFiscalYearEditModal(!showFiscalYearEditModal);
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching data.</p>;

  return (
    <FiscalYearIndex
      handleSearchChange={handleSearchChange}
      placeholder={"Search Fiscal Year..."}
    >
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: "22px", fontWeight: "500" }}>Fiscal Year</p>
            <IconButton
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#064b4f",
                color: "#ececec",
                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#ececec",
                },
              }}
              onClick={handleCreateFiscalYear}
            >
              <AddIcon style={{ fontSize: 30 }} />
            </IconButton>
          </div>
          <div className="divider"></div>
          {filteredData?.length > 0 ? (
            <div className="">
              <div className="setting-accordion-header row align-items-center bg-white card-header accordionHeader">
                <div className="col-2 text-center">
                  <p className="headerStyle">No.</p>
                </div>
                <div className="col-3 text-center">
                  <p className="headerStyle">Name</p>
                </div>
                <div className="col-3 text-center">
                  <p className="headerStyle">Start Date</p>
                </div>
                <div className="col-3 text-center">
                  <p className="headerStyle">End Date</p>
                </div>
                <div className="col-1 text-center">
                  <p className="headerStyle">Action</p>
                </div>
              </div>
              {_DATA.currentData()?.map((info, index) => (
                <Card className="mb-2 mt-0" key={info?.id}>
                  <div className="row align-items-center bg-white card-header accordionHeader">
                    <div className="col-2 text-center">
                      <p className="prg">{index + 1 + (page - 1) * PER_PAGE}</p>
                    </div>
                    <div className="col-3 text-center">
                      <p className="prg">{info?.name}</p>
                    </div>
                    <div className="col-3 text-center">
                      <p className="prg">
                        {new Date(info?.start_date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="col-3 text-center">
                      <p className="prg">
                        {new Date(info?.end_date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="col-1 text-center d-flex justify-content-around">
                      <IconButton
                        size="small"
                        sx={{
                          backgroundColor: "#fff",
                          color: "#1976d2",
                          "&:hover": {
                            backgroundColor: "#1976d2",
                            color: "#fff",
                          },
                        }}
                        onClick={() => handleEditFiscalYear(info)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{
                          backgroundColor: "#fff",
                          color: "#FF5252",
                          "&:hover": {
                            backgroundColor: "#FF5252",
                            color: "#fff",
                          },
                        }}
                        onClick={() => handleDeleteFiscalYear(info?.id)}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>
                </Card>
              ))}
              <div className="mt-3 mb-3">
                {count > 1 && (
                  <Pagination
                    count={count}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
      {showFiscalYearEditModal && (
        <EditFiscalYearModal
          name={fiscalYearName}
          start_date={fiscalYearStartDate}
          end_date={fiscalYearEndDate}
          id={fiscalYearId}
          onClose={handleClose}
        />
      )}
      {showFiscalYearDeleteModal && (
        <DeleteFiscalYearModal id={fiscalYearId} onClose={handleCloseDelete} />
      )}
      {createFiscalYearModal && (
        <CreateFiscalYear onClose={handleCloseCreate} />
      )}
    </FiscalYearIndex>
  );
};

export default FiscalYear;
