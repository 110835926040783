import React, { useState } from "react";
import axios from "axios";
import {
  useCreateOrderMutation,
  useCreateOrderServiceMutation,
  useCreateTraningOrderMutation,
  useGetBoqtypesQuery,
  useGetCurrenciesQuery,
  useGetManufacturersQuery,
  useGetMillstonesQuery,
  useGetProductsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetServiceTypeQuery,
  useGetSuppliersOrdersQuery,
  useGetSuppliersQuery,
  useGetSettingBusinessUnitQuery,
} from "../../features/SCMApi";
import MultiSelect from "../common/MultiSelect";
import ManufacturerForm from "./ManufacturerForm";
import { API_BASE_URL } from "../../api/endPoint";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink, useParams } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import FileSaver from "file-saver";
import PiTemplate from "../../assets/Template/PI_Template_traning.xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MuiSelect from "../common/MuiSelect";
import SelectOption from "../common/SelectOption";
import ErrorModal from "../common/ErrorModal";
import attachmentIcon from "../../assets/SVG/attach.svg";

export default function CreateTraningOrder(props) {
  const params = useParams();
  const [count, setCount] = useState([0]);
  const [order_type, setOrderType] = useState();
  const [supplier_id, setSupplier] = useState(null);
  const [bill_to, setbill] = useState();
  const [project_id, setProject] = useState();
  const [solution_id, setSolution] = useState();
  const [milestone_id, setMilestone] = useState();
  const [description, setDescription] = useState();
  const [currency_id, setCurrency] = useState();
  const [pi_number, setPiNumber] = useState();
  const [business_unit_id, setBusinessUnit] = useState();
  // const [pi, setPi] = useState();
  const solutionsList = [];
  const suppliersList = [];
  const { data: projects } = useGetProjectsQuery();
  const { data: serviceType } = useGetServiceTypeQuery();
  const { data: businessUnit } = useGetSettingBusinessUnitQuery();
  const [Manufacturer, setManufacture] = useState();
  const performa_invoice = [];
  const manufacturer_id = [];
  const [open, setOpen] = React.useState(false);
  const [errormessage, setErrorMessage] = useState(null);
  const { data: projectid } = useGetProjectQuery(params.id);
  let bypass = projectid?.data?.product_boq?.length == 0 ? true : false;
  const [is_offshore, setOffshore] = useState(false);
  const [pi_image, setpi_image] = useState();

  const {
    data: Solutions,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetProductsQuery();
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  const { data: Manufacturers } = useGetManufacturersQuery();
  const { data: currencies } = useGetCurrenciesQuery();
  const [uploading, setUploading] = useState(false);
  Solutions?.data?.map((items) =>
    solutionsList.push({
      value: items?.id,
      label: items?.abbrivation,
    })
  );
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );

  const list = [];
  Suppliers?.data?.map((items) => {
    list.push({
      value: items?.id,
      label: items?.distributor_name,
    });
  });
  // const [createOrder, errorsorder] = useCreateOrderMutation();
  const [createOrder, errorsorder] = useCreateTraningOrderMutation();
  // const [createOrderService] = useCreateOrderServiceMutation();

  function handleChange(e) {
    // setBoq(e?.target?.files?.[0]);
  }
  const param = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [isStored, setIsStored] = useState(null);

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  function handleSubmit(e) {
    setUploading(true);
    e.preventDefault();
    const manufacturer_id = [];
    const pi = [];
    performa_invoice?.map((items) => pi.push(items?.pi));
    performa_invoice?.map((items) =>
      manufacturer_id.push(items?.manufacturer_id)
    );

    const form2 = new FormData();
    form2.append("order_type", props?.type);
    form2.append("supplier_id", supplier_id?.value);
    form2.append("project_id", param.id);
    form2.append("bill_to", bill_to?.value);
    form2.append("milestone_id", milestone_id);
    form2.append("description", description);
    form2.append("bypass_pa", 0);
    form2.append("is_offshore", is_offshore == false ? 0 : 1);
    form2.append("currency_id", currency_id);
    form2.append("pi_number", pi_number);
    form2.append("pi_image", pi_image);
    form2.append("business_unit_id", business_unit_id);
    manufacturer_id.forEach((item) => {
      form2.append("manufacturer_id[]", item);
    });
    pi.forEach((item) => {
      form2.append("pi[]", item);
    });
    form2.append("solution_id[]", "12");

    createOrder(form2)
      .unwrap()
      .then((response) => {
        props?.setMessage("Traning Created Successfully");
        props.modal(false);
        setUploading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.data?.message);

        setErrorModalOpen(true);
        setUploading(false);
      });
  }
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };
  if (isSuccess) {
  }
  const { data: milestone } = useGetMillstonesQuery();

  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }

  const HandleCancel = () => {
    props.modal(false);
  };

  const [checked, setChecked] = useState(true);
  const CheckBoxHandel = () => {
    setChecked(true);
  };
  const CheckBoxHandelonShore = () => {
    setOffshore(!is_offshore);
  };
  const handleDownload = () => {
    FileSaver.saveAs(PiTemplate, "PI Template.xlsx");
  };

  return (
    <div>
      {" "}
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Create Order-({props?.type})</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <div className="lc-warning ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="24"
                height="24"
                fill="white"
                fill-opacity="0.01"
              ></rect>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                fill="white"
              ></path>
            </svg>
            <div>
              Please make sure the PI file you are about to import follows the
              proper format
            </div>
            <Tooltip title="Download PI Template">
              <DownloadIcon onClick={handleDownload} sx={{ color: "#fff" }} />
            </Tooltip>
            {error?.status} {JSON?.stringify(error?.data)}
          </div>
          {projectid?.data?.product_boq?.length == 0 ? (
            <div className="lc-warning bg-danger ">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  fill-opacity="0.01"
                ></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                  fill="white"
                ></path>
              </svg>
              <div>This project doesn't have a BoQ</div>
            </div>
          ) : (
            ""
          )}

          {uploading ? (
            <div className="inputs-group " style={{ width: "55%" }}>
              <div className="input-label">Uploading</div>
              <div class="loader"></div>
            </div>
          ) : (
            <></>
          )}
          {isErrorModalOpen && (
            <ErrorModal
              open={isErrorModalOpen}
              handleClose={closeErrorModal}
              errormessage={errormessage}
            />
          )}
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="d-flex  justify-content-center gap-2 px-2">
                <input
                  type="checkbox"
                  className="stock-checkmark"
                  onChange={CheckBoxHandelonShore}
                />
                <span className="h6">Off Shore</span>
              </div>
              <div className="d-flex justify-content-center my-2">
                <NavLink
                  to={`/sourcing/traningpricecomparison/${params?.id}`}
                  className="price-comparison"
                >
                  <AddIcon />
                  Price Comparison
                </NavLink>
              </div>

              <div className="input-groups">
                <div className="input-label">Pi Number</div>
                <input
                  typ="text"
                  className="select-option-create-order"
                  required
                  onChange={(e) => setPiNumber(e.target.value)}
                />
                <div className="input-label">PI Attach</div>
                <div
                  className="file-chooser cl-9 px-0"
                  style={{ backgroundImage: `url(${attachmentIcon})` }}
                >
                  <input
                    id="chooser"
                    type={"file"}
                    class="file-input"
                    onChange={(e) => setpi_image(e?.target?.files?.[0])}
                  />
                  <label
                    htmlFor="chooser"
                    style={{
                      position: "relative",
                      top: "-28px",
                      left: "5px",
                      width: "12rem",
                      // zIndex: "-1",
                    }}
                  >
                    {pi_image
                      ? pi_image?.name?.length > 16
                        ? pi_image?.name?.slice(0, 15) + "..."
                        : pi_image?.name
                      : "Choose File"}
                  </label>
                </div>
                <div className="input-label">Supplier</div>

                <SelectOption
                  options={list}
                  setters={setSupplier}
                  value={supplier_id}
                  isinput={true}
                />
                {/* <MuiSelect options={list} /> */}
                <div className="input-label">Bill to</div>

                <SelectOption
                  options={list}
                  setters={setbill}
                  value={bill_to}
                  isinput={true}
                />

                <div className="input-label">Currency</div>
                <select
                  name=""
                  id=""
                  className="select-option-create-order "
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Currency
                  </option>

                  {currencies?.data?.map((items) => (
                    <option value={items.id}>{items?.name}</option>
                  ))}
                </select>

                <div className="input-label">Business Unit</div>
                <select
                  name=""
                  id=""
                  required
                  className="select-option-create-order "
                  onChange={(e) => setBusinessUnit(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose business unit
                  </option>

                  {businessUnit?.data?.map((items) => (
                    <option value={items.id}>{items?.name}</option>
                  ))}
                </select>

                <div className="input-label">Millstone</div>

                <select
                  name=""
                  id=""
                  className="select-option-create-order "
                  onChange={(e) => setMilestone(e.target.value)}
                >
                  {" "}
                  <option selected="true" disabled="disabled">
                    Choose Millstone
                  </option>
                  {milestonepush?.map((items) => (
                    <option value={items.id}>{items?.name}</option>
                  ))}
                </select>
                <div className="input-label">Description</div>
                <textarea
                  type="text"
                  className="sourcing-text-input-textarea"
                  rows={4}
                  cols={50}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            {count?.map((item, i) => (
              <ManufacturerForm
                key={i}
                manufacturer={Manufacturers?.data}
                ManufacturerSetter={manufacturer_id}
                PiSetter={performa_invoice}
                count={i}
                type={props?.type}
              />
            ))}
            <button
              className="add-client-representative"
              style={{ marginLeft: "90%" }}
              onClick={(event) =>
                setCount((current) => [...current, count[1] + 1])
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                  fill="white"
                />
              </svg>
            </button>
            {isStored === true ? (
              <>
                <p className="text-danger h5">you have an error</p>
                <button type="submit" class="create-project-btn">
                  For More Information
                </button>
              </>
            ) : (
              <></>
            )}
            {projectid?.data?.product_boq?.length == 0 ? (
              <div class="form-buttons mb-3">
                <button
                  type="cancel"
                  class="form-cancel"
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button type="submit" class="form-save">
                  Save
                </button>
              </div>
            ) : (
              <div class="form-buttons ">
                <button
                  type="cancel"
                  class="form-cancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button type="submit" class="form-save">
                  Save
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
